<template>
  <div>
    <v-card>
      <v-card-title class="text-h6">{{ $t("labels.no_goods") }}</v-card-title>
      <v-card-text>{{ $t("messages.confirm_receipt_no_goods") }}</v-card-text>

      <v-card-text>
        <div class="mb-3">
          <v-text-field
            v-model="tracking_id"
            class="c-input-small"
            type="text"
            :label="$t('labels.session_code')"
            autofocus
            dense
            outlined
            clearable
            hide-details
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            v-model.number="sku"
            class="c-input-small"
            type="number"
            :label="$t('labels.sku')"
            dense
            outlined
            clearable
            hide-details
          ></v-text-field>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="cancel">
          {{ $t("labels.cancel") }}
        </v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="confirm"
          :disabled="isDisabledBtn"
        >
          {{ $t("labels.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "ConfirmNoGoods",
  components: {},
  props: {},
  data: () => ({
    isLoading: false,
    tracking_id: null,
    sku: null,
  }),
  computed: {
    isDisabledBtn() {
      return !this.tracking_id || !this.sku;
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    cancelRoll() {
      this.rollDialog = false;
    },
    async confirm() {
      if (this.isDisabledBtn) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/receipt-confirm-no-goods", {
          tracking_id: this.tracking_id,
          sku: this.sku,
        });
        this.isLoading = false;
        this.$root.$emit("playSuccessAudio");
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },
  },
};
</script>

<style scoped></style>
